import { useState } from "react";

import { ManageCPFCal } from "../../_service";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
} from "../../components";
import { CPFLimitDataProps } from "../../_types";
import moment from "moment";

interface ManageCPFLimitRowProps {
  data: CPFLimitDataProps;
  syncData: () => void;
}

const ManageAWLimitRow = (props: ManageCPFLimitRowProps) => {
  const { data, syncData } = props;

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    years.push(i);
  }

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  // const [effectiveFrom, setEffectiveFrom] = useState<string>(
  //   data.effective_from?.split("-")[0] || `${currentYear}`
  // );
  // const [effectiveTo, setEffectiveTo] = useState<string>(
  //   data.effective_to?.split("-")[0] || `${currentYear}`
  // );
  const [effectiveFrom, setEffectiveFrom] = useState<string>(
    data.effective_from
  );
  const [effectiveTo, setEffectiveTo] = useState<string>(data.effective_to);
  
  const [maxAw, setMaxAw] = useState<string>(data.aw);

  const user = useAppSelector((state) => state.user);

  const toggle = () => setEdit((prev) => !prev);

  const handleCpfTableDataUpdate = async () => {
    setUpdating(true);
    const formData = {
      // effective_from: `${effectiveFrom}-01-01`,
      // effective_to: `${effectiveTo}-12-31`,
      effective_from: `${effectiveFrom}`,
      effective_to: `${effectiveTo}`,
      aw: maxAw,
    };
    if (parseInt(maxAw) > 102000) {
      Notify("Max AW can't be greater than 102000", 0);
      setUpdating(false);
    } else {
      const res = await ManageCPFCal._updateAwData(data.id as string, formData);

      if (res.status === 200) {
        syncData();
        Notify("Data Updated Successfully", 1);
      } else {
        Notify("Data Updating Failed", 0);
      }
      setUpdating(false);
      setEdit(false);
    }
  };
  return (
    <tr>
      {edit ? (
        <>
          <td>
            <div style={{ width: 150 }}>
              {/* <CustomSelectField
                value={effectiveFrom}
                onChangeOption={(value) => setEffectiveFrom(() => value)}
                option={years?.map((option) => ({
                  key: option,
                  value: option,
                }))}
              /> */}
              <CustomDateField
                date={effectiveFrom}
                setDate={setEffectiveFrom}
                startYear={new Date().getFullYear() - 1}
                endYear={new Date().getFullYear() + 10}
                openAsModal={true}
              />
            </div>
          </td>
          <td>
            <div style={{ width: 150 }}>
              {/* <CustomSelectField
                value={effectiveTo}
                onChangeOption={(value) => setEffectiveTo(() => value)}
                option={years?.map((option) => ({
                  key: option,
                  value: option,
                }))}
              /> */}
               <CustomDateField
                startYear={new Date().getFullYear() - 1}
                endYear={new Date().getFullYear() + 10}
                date={effectiveTo}
                setDate={setEffectiveTo}
                openAsModal={true}
              />
            </div>
          </td>
          <td>
            <CustomNumberField
              width="80px"
              value={maxAw}
              onChangeText={setMaxAw}
            />
          </td>
          <td>
            <CustomButton
              size="small"
              width="70px"
              title="Update"
              onClick={handleCpfTableDataUpdate}
              disable={updating}
            />
          </td>
        </>
      ) : (
        <>
          {/* <td>{Utils._dateToShow(data.effective_from).split("-").pop()}</td>
          <td>{Utils._dateToShow(data.effective_to).split("-").pop()}</td> */}
          <td>{moment(data.effective_from, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
          <td>{moment(data.effective_to, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
          <td>{data.aw}</td>
          {user.permission === "full" && (
            <td>
              <CustomButton
                type="secondary"
                size="small"
                width="50px"
                title="Edit"
                onClick={toggle}
              />
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default ManageAWLimitRow;
