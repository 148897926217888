import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

const ContainerBox = styled.div`
  width: 500px;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.BLACK_SHADOW};
`;
const Title = styled.h1`
  background-color: ${({ theme }) => theme.BLUE_1};
  padding: 1rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.WHITE};
`;
const InputFieldWrapper = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.WHITE_2};
  position: relative;
`;
const Link = styled(NavLink)`
  color: ${({ theme }) => theme.BLUE_3};
  font-size: 1rem;
  padding: 6px 1rem;
  margin: 0 0.5rem;
  border-radius: 10px;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.BLUE};
    text-decoration: underline;
  }
`;
const DontRememberLinkSection = styled.div`
  margin-top: 3rem;
`;
const DontRememberLink = styled(Link)`
  position: absolute;
  text-align: end;
  width: 90%;
`;
const BackToHomeLinkWrap = styled.div`
  padding: 2rem 0 1rem 0;
  border: 1px solid ${({ theme }) => theme.WHITE_2};
  position: relative;
`;
const BackToHomeLink = styled(Link)`
  text-align: center;
  width: 100%;
  display: block;
`;

// Create Password Screen
const PasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

const PasswordBox = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.WHITE_2};
  position: relative;
`;
const PasswordLinKBox = styled.div`
  padding: 2rem 0 1rem 0;
  border: 1px solid ${({ theme }) => theme.WHITE_2};
  position: relative;
`;
const PasswordHeader = styled.h1`
  background-color: ${({ theme }) => theme.BLUE_4};
  padding: 1rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.WHITE};
`;

export {
  Container,
  ContainerBox,
  Title,
  InputFieldWrapper,
  Link,
  DontRememberLinkSection,
  DontRememberLink,
  BackToHomeLinkWrap,
  BackToHomeLink,
  PasswordHeader,
  PasswordContainer,
  PasswordBox,
  PasswordLinKBox,
};
