import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { Card, Container, Heading, Navlink } from "../dashboard/Styles";
import { useEffect, useState } from "react";
import { ReportService } from "../../_service/ReportService";
import { Notify } from "../../utils";
import { Constant } from "../../config";
import { H3Heading } from "../appSettings/AppSettingsStyles";

const Reports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [catgeoryData, setCategoryData] = useState<any>([]);

  const navigate = useNavigate();

  const getCategoryList = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ReportService._getCategoryList();

      if (status === 200) {
        setCategoryData(() => data.data);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  return (
    <div style={{ position: "relative" }}>
      {isLoading && <Loader />}
      <div>
        <H3Heading>Reports</H3Heading>
        <Container>
          {catgeoryData
            ?.sort((a: any, b: any) => a.id - b.id)
            ?.map((category: any) => (
              <Card key={category.name}>
                <Heading>{(category.name || "").replaceAll("_", " ")}</Heading>
                {category?.fields?.map((field: any) => {
                  return (
                    <Navlink
                      className="data card-row-link"
                      to={`/dashboard/reports/${field.custom_id}`}
                      key={field?.custom_id}
                    >
                      {field?.name}
                    </Navlink>
                  );
                })}
              </Card>
            ))}
        </Container>
      </div>
    </div>
  );
};

export default Reports;
