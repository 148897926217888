import apiClient from "./BaseService";
import { URL } from "../config";

const _getRace = async () => apiClient.get(URL.GET_RACE_HELPER);

const _getReligion = async () => apiClient.get(URL.GET_RELIGION_HELPER);

const _getPaymentMethods = async () =>
  apiClient.get(URL.GET_PAYMENT_METHODS_HELPER);

const _getLegalStatus = async () => apiClient.get(URL.GET_LEGAL_STATUS_HELPER);

const _getCountry = async () => apiClient.get(URL.GET_COUNTRY_HELPER);

const _getBankList = async () => apiClient.get(URL.GET_BANK_LIST);

const _getAddressTypes = async () => apiClient.get(URL.GET_ADDRESS_TYPES);

export const HelperService = {
  _getCountry,
  _getLegalStatus,
  _getPaymentMethods,
  _getRace,
  _getReligion,
  _getBankList,
  _getAddressTypes,
};
