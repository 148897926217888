import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import "./add-company.css";
import { IconContext } from "react-icons";
import styled from "styled-components";
import { CustomButton } from "../customButton";

interface CommonModalProps {
  body?: string | JSX.Element;
  open: boolean;
  handleClose: () => void;
  updateData: () => void;
  onCancel?: () => void;
  loading?: boolean;
  heading?: string;
  conformButtonText?: string;
  cancelButtonText?: string;
  deleteButtonText?: string;
  isCancelButton?: boolean;
  padding?: number;
  deleteData?: () => void;
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #eee",
    borderRadius: "10px",
    maxWidth: "700px",
    padding: 0,
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
};

const CommonModal = (props: CommonModalProps) => {
  const {
    handleClose,
    onCancel,
    open,
    body,
    updateData,
    loading = false,
    heading = "Please confirm",
    conformButtonText = "Confirm",
    cancelButtonText = "Close",
    deleteButtonText = "",
    isCancelButton = true,
    padding = 40,
    deleteData = () => {},
  } = props;

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div>
        <Header>
          <h3>{heading}</h3>
          <span onClick={handleClose}>
            <IconContext.Provider value={{ color: "red", size: "20px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </span>
        </Header>
        <Body style={{ padding: padding }}>
          <p>{body}</p>
        </Body>
        {(conformButtonText || isCancelButton || deleteButtonText) && (
          <Footer>
            {conformButtonText && (
              <CustomButton
                title={conformButtonText}
                onClick={updateData}
                width="120px"
                loading={loading}
                disable={loading}
              />
            )}

            {isCancelButton && (
              <CustomButton
                title={cancelButtonText}
                type="secondary"
                onClick={onCancel || handleClose}
                width="120px"
              />
            )}
            {deleteButtonText && (
              <CustomButton
                title={deleteButtonText}
                type="secondary"
                onClick={deleteData}
                width="120px"
                del={true}
              />
            )}
          </Footer>
        )}
      </div>
    </Modal>
  );
};

export default CommonModal;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #059bbf;
  padding: 10px 20px 20px 20px;
  h3 {
    color: #059bbf;
  }
  span {
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 40px;
  p {
    color: grey;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: "60px";
  background-color: #f5f5f5;
  padding: 14px 15px 15px;
  border-top: 1px solid #059bbf;
`;
