import { TableHeader } from "../../../components/table";
import { Navlink } from "../../dashboard/Styles";
import {
  IRASPdfContainer,
  IRASTableCol,
  TaxCertificateContainer,
} from "../CPFFilingStyles";
import { IRASTable } from "../CPFFilingStyles";
import PdfIcon from "../../../assets/pdf-icon.png";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { CPFService } from "../../../_service/CPFService";
import { useAppSelector } from "../../../_app";
import { useEffect, useState } from "react";
import { CustomSelectField, Loader } from "../../../components";
import { LoaderContainer } from "../../../CommonStyles";
import { useDispatch } from "react-redux";
import { updateUserIntialState } from "../../../_features";
import moment from "moment";

const IRASFiling = () => {
  const appUser = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [selectedSession, setSelectedSession] = useState<string>(
  //   appUser?.filing_date || moment(`${moment().year() - 1}-12-31`).format('YYYY-MM-DD')
  // );
  const [selectedSession, setSelectedSession] = useState<string>(
    moment(`${moment().year()}-12-31`).format('YYYY-MM-DD')
  );
  
  const [ir8aEmployeeList, setIr8aEmployeeList] = useState<any>([]);

  const dispatch = useDispatch();

  

  const IRASHeading = [
    {
      name: "Employee",
      width: "40%",
    },
    {
      name: "Number",
      width: "15%",
    },
    {
      name: "Date",
      width: "15%",
    },
    {
      name: "Type",
      width: "15%",
    },
    {
      name: "View",
      width: "15%",
    },
  ];

  function generateDateList() {
    let currentYear = moment().year();

    let datesList = [];

    for (let year = currentYear - 3; year <= currentYear + 7; year++) {
        let date = moment(`${year}-12-31`).format('YYYY-MM-DD');
        datesList.push(date);
    }

    return datesList;
}

// const sessionArray = ["2023-12-31", "2022-12-31"];
const sessionArray = generateDateList();

  const getIrasPdf = async (id: any, name: string) => {
    try {
      const response = await CPFService._getPdfIRAS(id);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}_iras.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getAllIr8aFiling = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await CPFService._getAllIr8aFiling(
        selectedSession,
        appUser.defaultCompnayId
      );

      if (status === 200) {
        setIr8aEmployeeList(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getAllIr8aFiling();
    dispatch(
      updateUserIntialState({
        ...appUser,
        filing_date: selectedSession,
      })
    );
  }, [selectedSession]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      {/* <div
        style={{
          display: "flex",
          width: "70%",
          gap: 20,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#333", marginTop: 10 }}>Select Month and Year</p>
        <div>
          <CustomSelectField
            value={month}
            onChangeOption={(value) => setMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
        <div>
          <CustomSelectField
            value={year}
            onChangeOption={(value) => setYear(value)}
            option={Utils.generateYearArrayTillNow(2022).map((year) => ({
              key: year,
              value: year,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
      </div> */}
      <CustomSelectField
        title="Filing Season"
        value={selectedSession}
        onChangeOption={(value) => setSelectedSession(value)}
        option={sessionArray.map((session) => ({
          // key: Utils._dateToShow(session),
          key: Utils._dateToShow(session),
          value: session,
        }))}
        isDate
        width="400px"
      />
      
      <div style={{ marginTop: 20 }}>
        {ir8aEmployeeList.length > 0 ? (
          <IRASTable>
            <TableHeader headerColumns={IRASHeading} />
            <tbody>
              {ir8aEmployeeList?.sort((a:any, b:any) => a?.user?.name?.toLowerCase().localeCompare(b?.user?.name?.toLowerCase())).map((ir8aReport: any) => (
                <tr>
                  <IRASTableCol>
                    <p>{ir8aReport?.user?.name || ""}</p>
                  </IRASTableCol>
                  <IRASTableCol>
                    {ir8aReport?.employee?.emp_number || ""}
                  </IRASTableCol>
                  <IRASTableCol>
                    {Utils._dateToShow(ir8aReport?.date) || ""}
                  </IRASTableCol>
                  <IRASTableCol>IR8A</IRASTableCol>
                  <IRASTableCol>
                    <a
                      onClick={() =>
                        getIrasPdf(
                          ir8aReport.id,
                          ir8aReport?.user?.first_name || ""
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <IRASPdfContainer>
                        <img
                          src={PdfIcon}
                          alt="PDF Icon"
                          style={{ width: "100%" }}
                        />
                      </IRASPdfContainer>
                    </a>
                    {appUser.permission === "full" && (
                      <Navlink
                        to="edit"
                        state={{ ir8aDetail: ir8aReport }}
                        style={{ padding: 0, marginLeft: 10 }}
                      >
                        Edit
                      </Navlink>
                    )}
                  </IRASTableCol>
                </tr>
              ))}
            </tbody>
          </IRASTable>
        ) : (
          <p style={{fontSize: '1rem', fontWeight: 600}}>No IR8A report present for the selected submission year.</p>
        )}
      </div>
      <TaxCertificateContainer>
        {/* <Navlink to="">
          <IRASLogo src={PdfIcon} alt="PDF Icon" />
        </Navlink> */}
        {/* <Navlink to="">All Tax Certifcates (2)</Navlink> */}
        {/* <Navlink to="">
          <IRASLogo src={ExcelIcon} alt="Excel Icon" />
        </Navlink> */}
      </TaxCertificateContainer>
      {/* <ReconciliationHeading>Reconciliation Report</ReconciliationHeading>
      <Navlink to="" style={{ padding: 0 }}>
        Excel
      </Navlink> */}
    </div>
  );
};

export default IRASFiling;
