import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../fonts/NotoSans-Regular.ttf";
import Logo from "../../assets/Logo.png";

import { Utils } from "../../utils";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
  },
  fullPage: {
    color: "black",
    fontSize: "10px",
    padding: "40px",
    display: "flex",
    justifyContent: "space-between",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "12px",
  },

  employeeBasicInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  boldText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
});

// Create Document Component
function ReportDocument({
  documents,
  finalized = false,
  job = false,
  startDate,
  lastDate,
  type
}: {
  documents: any;
  finalized?: boolean;
  job?: boolean;
  startDate: string,
  lastDate: string,
  type: string,
}) {
  return (
    <Document>
      {/*render a single page*/}
      {documents?.map((document: any, index: number) => {
        return (
          <Page key={String(index)} size="A4" style={styles.fullPage}>
            <View style={styles.page}>
              <View>
                <View style={styles.header}>
                  <Text></Text>
                  <Text>{document?.employee?.company?.name || ""}</Text>
                  {/* {!document?.is_finalized ? (
                    <Text
                      style={{
                        position: "relative",
                        bottom: "20px",
                        transform: "rotate(45deg)",
                        border: "1px solid red",
                        padding: "2px",
                        fontSize: "8px",
                        color: "red",
                      }}
                    >
                      DRAFT
                    </Text>
                  ) : (
                    <Text></Text>
                  )} */}
                </View>
                <View
                  style={{
                    borderBottom: "2px solid #000",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "0px 4px 20px 4px",
                      width: "100%",
                    }}
                  >
                    <View>
                      <View style={styles.employeeBasicInfo}>
                        <Text style={styles.boldText}>Employee Name</Text>
                        <Text style={{ marginLeft: "48px" }}>{`${
                          document.employee.first_name ??
                          document.employee.user.first_name
                        } ${
                          document.employee.last_name ??
                          document.employee.user.last_name
                        }`}</Text>
                      </View>
                      <View style={styles.employeeBasicInfo}>
                        <Text style={styles.boldText}>Period</Text>
                        <Text
                          style={{ marginLeft: "48px" }}
                        >{`${type === "history" ? document?.payslip_info?.payslip_date?.start : startDate} to ${type === "history" ? document?.payslip_info?.payslip_date?.end : lastDate}`}</Text>
                      </View>
                      <View style={styles.employeeBasicInfo}>
                        <Text style={styles.boldText}>NRIC/FIN</Text>
                        <Text style={{ marginLeft: "48px" }}>
                          {document?.employee?.legal_status_detail[0]?.nric ||
                            document?.employee?.legal_status_detail[0]?.fin ||
                            "-"}
                        </Text>
                      </View>
                      <View style={styles.employeeBasicInfo}>
                        <Text style={styles.boldText}>Job Title</Text>
                        <Text style={{ marginLeft: "48px" }}>
                          {document?.employee?.job_title || "-"}
                        </Text>
                      </View>
                      <View style={styles.employeeBasicInfo}>
                        <Text style={styles.boldText}>Employment Date</Text>
                        <Text style={{ marginLeft: "48px" }}>
                          {Utils.getFormatedDate(
                            document?.employee?.date_of_appointment
                          ) || "-"}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <View>
                        <Text>
                          {document?.employee?.company?.address_l1 || ""}
                        </Text>
                        <Text>
                          {document?.employee?.company?.address_l2 || ""}
                        </Text>
                        <Text>
                          {document?.employee?.company?.address_l3 || ""}{" "}
                          {document?.employee?.company?.address_code || ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <View style={{ flexBasis: "48%" }}>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        marginBottom: "5px",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Text style={styles.boldText}>Income</Text>
                      <Text style={styles.boldText}>
                        {document?.payslip_info?.income?.total || "-"}
                      </Text>
                    </View>
                    {document?.payslip_info?.income?.data?.map((item: any) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ maxWidth: 200 }}>
                            {item?.name || "-"}
                          </Text>
                          <Text style={{ maxWidth: 200 }}>
                            {item?.value || "-"}
                          </Text>
                        </View>
                      );
                    })}
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        marginTop: "10px",
                        marginBottom: "5px",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Text style={styles.boldText}>Deduction</Text>
                      <Text style={styles.boldText}>
                        {document?.payslip_info?.deductions?.total}
                      </Text>
                    </View>
                    {document?.payslip_info?.deductions?.data?.map(
                      (deduction: any) => {
                        return (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              gap: 50,
                            }}
                          >
                            <Text style={{ maxWidth: 200 }}>
                              {deduction.name}
                            </Text>
                            <Text style={{ maxWidth: 200 }}>
                              {deduction.value}
                            </Text>
                          </View>
                        );
                      }
                    )}
                  </View>
                  <View style={{ flexBasis: "48%" }}>
                    {!!document?.payslip_info?.reimbursement?.total && (
                      <>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <Text style={styles.boldText}>Reimbursements</Text>
                          <Text style={styles.boldText}>
                            {document.payslip_info?.reimbursement?.total ||
                              "0.00"}
                          </Text>
                        </View>
                        {document?.payslip_info?.reimbursement?.data?.map(
                          (reimbursement: any) => {
                            return (
                              <View
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                }}
                              >
                                <Text style={{ maxWidth: 200 }}>
                                  {reimbursement.name}
                                </Text>
                                <Text style={{ maxWidth: 200 }}>
                                  {reimbursement.value}
                                </Text>
                              </View>
                            );
                          }
                        )}
                      </>
                    )}
                    {!!document?.payslip_info?.allowance?.total && (
                      <>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <Text style={styles.boldText}>Allowances</Text>
                          <Text style={styles.boldText}>
                            {document.payslip_info?.allowance?.total || "0.00"}
                          </Text>
                        </View>
                        {document?.payslip_info?.allowance?.data?.map(
                          (allowance: any) => {
                            return (
                              <View
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                }}
                              >
                                <Text style={{ maxWidth: 200 }}>
                                  {allowance.name}
                                </Text>
                                <Text style={{ maxWidth: 200 }}>
                                  {allowance.value}
                                </Text>
                              </View>
                            );
                          }
                        )}
                      </>
                    )}
                  </View>
                </View>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginTop: "30px",
                    borderTop: "1px solid #000",
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <Text style={[styles.boldText, { marginRight: "25px" }]}>
                    NET PAY
                  </Text>
                  <Text style={styles.boldText}>
                    $ {document?.payslip_info?.net_pay || "-"}
                  </Text>
                </View>
              </View>
              <View style={{ width: "48%", marginTop: 20 }}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    // marginTop: "5px",
                    marginBottom: "5px",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <Text style={styles.boldText}>Employer Contribution</Text>
                  <Text style={styles.boldText}>
                    {document.payslip_info.employer_contribution.total}
                  </Text>
                </View>
                {document?.payslip_info?.employer_contribution.data?.map(
                  (deduction: any) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ maxWidth: 200 }}>{deduction.name}</Text>
                        <Text style={{ maxWidth: 200 }}>{deduction.value}</Text>
                      </View>
                    );
                  }
                )}
              </View>
            </View>

            <View
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image src={Logo} style={{ width: "80px", marginTop: "20px" }} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
}
export default ReportDocument;
